@use 'utils' as *;

[type='radio'],
[type='checkbox'] {
    appearance: none;
    border: 1px solid var(--color-error);
    cursor: pointer;
    display: inline-block;
    height: 1.29em;
    position: relative;
    width: 1.29em;

    &::after {
        content: '';
        height: 100%;
        inset: 0;
        opacity: 0;
        position: absolute;
        width: 100%;
    }

    &:disabled {
        filter: grayscale(1);
        opacity: 0.4;
    }

    &:focus-visible {
        background-color: var(--color-focus);
        outline: none;
    }

    &:checked::after {
        opacity: 1;
    }

    label & {
        margin-block-start: 0;
    }

    .amt-error {
        color: var(--color-error);
    }
}

[type='checkbox']::after {
    background: linear-gradient(var(--color-error), var(--color-error)) no-repeat 50% 50% / 0.7em
        0.7em;
}

[type='radio'] {
    border-radius: 50%;

    &::after {
        background-clip: content-box;
        background-color: var(--color-main);
        border-radius: 50%;
        padding: 0.2em;
    }
}

[type='date'],
[type='email'],
[type='password'],
[type='text'],
select,
textarea {
    appearance: unset;
    background-color: $color-dark-grey-3;
    border: none;
    color: $color-light-grey-1;
    font-family: $font-poppins;
    padding: 1em 1.14em;
    position: relative;
    width: 100%;

    &:focus {
        color: var(--color-error);
        outline: solid 1px var(--color-error);
        outline-offset: -4px;
    }

    &:disabled,
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.error {
        color: var(--color-error);
    }

    label & {
        left: 0;
        position: absolute;
        top: 0;
    }
}

select {
    background-color: rgb(var(--color-main-rgb) / 50%);

    &:focus {
        background-color: rgb(var(--color-focus-rgb) / 40%);
        outline-offset: -4px;
    }
}

[type='date'] {
    color-scheme: dark;
}

.amt-error {
    color: var(--color-error);
}
