@layer amt-forms {
    // Baseline Styles

    fieldset {
        border: unset;
    }

    [type='radio'],
    [type='checkbox'] {
        appearance: none;
        cursor: pointer;
        display: inline-block;
        height: 1.29em;
        position: relative;
        width: 1.29em;

        &::after {
            content: '';
            height: 100%;
            inset: 0;
            opacity: 0;
            position: absolute;
            width: 100%;
        }

        &:disabled {
            filter: grayscale(1);
            opacity: 0.4;
        }

        &:focus-visible {
            outline: none;
        }

        &:checked::after {
            opacity: 1;
        }

        label & {
            margin-block-start: 0;
        }
    }

    [type='checkbox']::after {
        background-color: currentcolor;
    }

    [type='radio'] {
        border-radius: 50%;

        &::after {
            background-clip: content-box;
            background-color: currentcolor;
            border-radius: 50%;
            padding: 0.2em;
        }
    }

    [type='date'],
    [type='datetime-local'],
    [type='email'],
    [type='file'],
    [type='number'],
    [type='password'],
    [type='text'],
    select,
    textarea {
        appearance: unset;
        border: 1px solid;
        padding-block: 0.7em;
        padding-inline: 1em;
        position: relative;
        width: 100%;

        &:focus-within {
            outline: 1px dashed;
        }

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    [type='file'] {
        &::file-selector-button {
            font-size: 0.8em;
            margin-right: 1em;
        }
    }

    select {
        &:focus {
            outline-offset: 0.2em;
        }
    }

    textarea {
        resize: vertical;
    }

    [type='date'],
    [type='datetime-local'] {
        color-scheme: dark;
    }

    [type='file']::file-selector-button,
    button {
        border: 1px solid;
        border-radius: 0.29em;
        cursor: pointer;
        display: inline-block;
        line-height: 1.4em;
        min-width: 4rem;
        padding-block: 0.7em;
        padding-inline: 1em;
        text-align: center;
        text-transform: uppercase;
    }

    .amt-field {
        display: grid;
        grid-template:
            'label' auto
            'input' auto
            'error' auto
            / 1fr;

        &--hide {
            display: none;
        }

        &:has([type='radio'], [type='checkbox']) {
            align-items: center;
            grid-template:
                'input label' auto
                '. error' auto
                / auto 1fr;
        }
    }

    .amt-label {
        display: block;
        grid-area: label;
        position: relative;
    }

    .amt-input {
        grid-area: input;
        width: 100%;
    }

    .amt-error {
        display: block;
        grid-area: error;
    }

    // Select Input
    .amt-select {
        --arrow-svg: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 5" fill="transparent"><path stroke="black" d="m1 .828 2.828 2.829L6.657.828"/></svg>');

        position: relative;
        z-index: 0;

        &-input {
            width: 100%;
        }

        &-arrow {
            aspect-ratio: 1;
            background-color: currentcolor;
            height: 100%;
            mask-image: var(--arrow-svg);
            mask-position: 50% 50%;
            mask-repeat: no-repeat;
            mask-size: 0.8em 0.5em;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;

            :focus-within > & {
                rotate: 180deg;
            }
        }
    }

    // Combobox Input
    .amt-combobox {
        --list-bg: #000;
        --list-txt: #fff;
        --highlighted-bg: #fff;
        --highlighted-txt: #000;
        --selected-bg: #fff;
        --selected-txt: #000;

        position: relative;

        &-group {
            position: relative;
            z-index: 0;
        }

        &-input {
            display: block;
            width: 100%;
        }

        &-arrow {
            color: currentcolor;
            height: 1.2em;
            pointer-events: none;
            position: absolute;
            right: 1em;
            top: 50%;
            translate: 0 -50%;
            width: 1.2em;
            z-index: 1;
        }

        &-list {
            background-color: var(--list-bg);
            border: 1px solid var(--list-txt);
            left: 0;
            list-style: none;
            max-height: 30svh;
            overflow: auto;
            position: absolute;
            right: 0;
            scrollbar-color: var(--list-txt) transparent;
            scrollbar-width: thin;
            top: 100%;
            z-index: 100;

            &::-webkit-scrollbar {
                background-color: transparent;
                height: 0.6rem;
                width: 0.6rem;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--list-txt);
            }
        }

        &-option {
            cursor: pointer;

            &:hover {
                background-color: var(--highlighted-bg);
                color: var(--highlighted-txt);
            }

            &--selected {
                background-color: var(--selected-bg);
                color: var(--selected-txt);
            }
        }
    }
}
