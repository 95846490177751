@use 'utils' as *;

// Text Highlight Styles
::selection {
    background: var(--color-main);
    color: var(--color-base);
}

body {
    @include paragraph;
}

h1 {
    @include title-h1;

    text-wrap: balance;
}

h2 {
    @include title-h2;

    text-wrap: balance;
}

h3 {
    @include title-h3;

    text-wrap: balance;
}

h4 {
    @include title-h4;
}

h5 {
    @include title-h5;
}

h6 {
    @include title-h6;
}

@media (width < 724px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        hyphens: auto;
    }
}

a:not([class]) {
    text-decoration: underline;
}

b,
strong {
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

u {
    text-decoration: underline;
}

label {
    @include label;
}

button {
    @include button;
}
