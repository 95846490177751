@use 'utils' as *;
@use 'reset' as *;
@use 'typography' as *;
@use 'forms' as *;

// Page Defaults
:root {
    // Typed Colors
    --color-main: #fff;
    --color-main-rgb: prism(#fff);
    --color-base: #000;
    --color-base-rgb: prism(#000);
    --color-success: #4d5;
    --color-success-rgb: prism(#4d5);
    --color-error: #ff4e4e;
    --color-error-rgb: prism(#ff4e4e);
    --color-focus: #0cf;
    --color-focus-rgb: prism(#0cf);

    // Named Colors
    --color-blue: var(--color-focus);
    --color-blue-rgb: var(--color-focus-rgb);
    --color-gray: #c3c3c3;
    --color-gray-rgb: prism(#c3c3c3);

    // Animations
    --animation-timing: 200ms;
    --animation-ease-in: cubic-bezier(0, 0, 0.2, 1);
    --animation-ease-out: cubic-bezier(0.4, 0, 1, 1);
    --animation-ease-within: cubic-bezier(0.4, 0, 0.2, 1);
}

* {
    scrollbar-color: rgb(var(--color-main-rgb) / 20%) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        background-color: transparent;
        height: 0.6rem;
        width: 0.6rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(var(--color-main-rgb) / 20%);
        border-radius: 10px;
    }
}

html {
    height: 100%;

    &.mobile-nav-open {
        max-width: 100vw;
        overflow: hidden;
    }
}

body {
    background: var(--color-base) no-repeat 50% 0 / 100% auto;
    color: var(--color-main);
    display: grid;
    font-family: var(--poppins), var(--noto-sans), sans-serif;
    grid-template:
        'header' auto
        'main' minmax(min-content, 1fr)
        'footer' auto
        / 1fr;
    min-height: 100%;
}

.ReactModal__Body--open {
    overflow: hidden;
}
